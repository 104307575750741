import Vue from 'vue'

import aiMee from "@/aiMee";
// import survey from "@/survey";

import vuetify from './plugins/vuetify'
import TrendChart from "vue-trend-chart";


Vue.config.productionTip = false
Vue.use(TrendChart);
// Vue.use(survey);

new Vue({
  vuetify,
  render: h => h(aiMee)
  // render: h => h(survey)
}).$mount('#app')

