<template>
  <v-app>
    <div id="app">
      <h1>aiMee</h1>
<!--          width="50%" alt="aiMee logo" src="./assets/aiMee-logo.png"-->
      <img
        src="@/assets/aiMee-logo.png"
        alt="aiMee"
        :height="logoHeight"
      >
      <MainPage/>
    </div>
  </v-app>
</template>

<script>
import MainPage from "@/components/MainPage";

export default {
  name: 'App',
  components: {
    MainPage
  },
  computed: {
    logoHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "120px";
        case "sm":
          return "150";
        case "md":
          return "200px";
        case "lg":
          return "200px";
        case "xl":
          return "250px";
      }
      return "120px";
    },
  },

}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Arvo&display=swap');

#app {
  font-family: "Arvo", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
