<template>
    <BarChart :options="Options" :chartData="Data"/>
</template>

<script>
  import BarChart from '@/components/BaseCharts/BarChart'
  import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
  import { PastelTwo3 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.brewer';
  export default {
    name: "Visitors",
    props: [
      'ChartData',
      'CustomLabels',
    ],
    components: {
      BarChart,
    },
    data() {
      return {
        Data: {},
        Options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: 'bottom',
            rtl: true,
          },
          plugins: {
            datalabels: {
              textAlign: 'center',
              formatter: (value) => {
                var ret = value;
                return ret;
              },
              display: 'auto',
              clamp: true
            },
            colorschemes: {
              scheme: PastelTwo3,
            }
          }
        },
      }
    },
    methods: {
      updateData() {
        this.Data = {
          labels: this.CustomLabels,
          datasets: [
            {
              data: this.ChartData[0],
              label: "Number of visitors",
              fill: true,
              borderWidth: 2
            },
            {
              data: this.ChartData[1],
              label: "Avg visit length [min]",
              fill: true,
              borderWidth: 2
            },
          ],
        }
      }
    },
    mounted() {
      this.updateData()
    },
    watch: {
      ChartData: {
        handler() {
          this.updateData()
        },
        deep: true
      }
    },
  }
</script>