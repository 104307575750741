<template>
  <div class="hello">
    <h3>Number Of People Inside</h3>
    <NumberOfPeopleInsideLineChart
      :ChartData="this.graphs.numberOfPeople.yData"
      :CustomLabels="this.graphs.numberOfPeople.xData"
    />
    <h3>Visitors</h3>
    <Visitors
      :ChartData="this.graphs.visitors.yData"
      :CustomLabels="this.graphs.visitors.xData"
    />
    <br>
    <br>
    <v-row no-gutters>
      <v-col>
        <v-btn
          color="primary"
          elevation="5"
          large
          @click="updateCounter()"
        >Today</v-btn>
      </v-col>
      <v-col>
        <v-btn
          color="primary"
          elevation="5"
          large
          @click="updateCounter(1)"
        >Yesterday</v-btn>
      </v-col>
      <v-col>
        <v-btn
          color="primary"
          elevation="5"
          large
          @click="updateCounter(7)"
        >Last week</v-btn>
      </v-col>
      <v-col>
        <v-btn
          color="primary"
          elevation="5"
          large
          @click="updateCounter(30)"
        >Last Month</v-btn>
      </v-col>
      <v-col>
        <v-btn
          color="primary"
          elevation="5"
          large
          @click="updateCounter(365)"
        >Last Year</v-btn>
      </v-col>
    </v-row>
    <br>
    <br>
    <br>

  </div>
</template>

<script>
import NumberOfPeopleInsideLineChart from "@/components/Metrics/NumberOfPeopleInsideLineChart";
import Visitors from "@/components/Metrics/VisitorsBarChart";

export default {
  name: 'Graphs',
  props: {},
  components: {
    Visitors,
    NumberOfPeopleInsideLineChart,
  },

  data() {
      return {
        host: "https://0ly48fm8j0.execute-api.us-east-2.amazonaws.com/api/v1",
        graphs: {
          "numberOfPeople": {
            xData: ['8:00', "9:00", '10:00', "11:00", '12:00', "13:00", '14:00', "15:00",
              '16:00', "17:00", '18:00', "19:00", '20:00', "21:00", '22:00'],
            yData: this.randomEntriesForAllClasses(),
          },
          "visitors": {
            xData: ['WOMEN', 'MEN', 'GIRLS', 'BOYS', 'BABIES'],
            yData: [[0, 1, 2, 1, 2], [16, 5, 13, 18, 7]]
          }
        }
      }
  },
  methods: {
    getRandomInt (min = 0, max = 0, mean_factor = 1) {
      return Math.floor(Math.random() * mean_factor * (max - min) + min)
    },
    randomEntries(mean_factor = 1) {
      return [
        this.getRandomInt(0, 20, mean_factor), this.getRandomInt(10, 40, mean_factor),
        this.getRandomInt(30, 150, mean_factor), this.getRandomInt(30, 150, mean_factor),
        this.getRandomInt(30, 150, mean_factor), this.getRandomInt(30, 150, mean_factor),
        this.getRandomInt(30, 150, mean_factor), this.getRandomInt(30, 150, mean_factor),
        this.getRandomInt(30, 150, mean_factor), this.getRandomInt(30, 150, mean_factor),
        this.getRandomInt(30, 150, mean_factor), this.getRandomInt(50, 200, mean_factor),
        this.getRandomInt(50, 200, mean_factor), this.getRandomInt(50, 200, mean_factor),
        this.getRandomInt(0, 20, mean_factor)
      ];
    },
    randomEntriesForAllClasses(mean_factor = 1) {
      console.log('run')
      return {
        "WOMEN": this.randomEntries(mean_factor),
        "MEN": this.randomEntries(mean_factor),
        "GIRLS": this.randomEntries(mean_factor),
        "BOYS": this.randomEntries(mean_factor),
        "BABIES": this.randomEntries(mean_factor)
      };
    },
    randomVisitLength() {
      return [this.getRandomInt(30, 120), this.getRandomInt(20, 40), this.getRandomInt(60, 180),
        this.getRandomInt(60, 180), this.getRandomInt(30, 100)];
    },
    sumVisitors() {
      return [
        this.graphs.numberOfPeople.yData.WOMEN.reduce((a, b) => a + b, 0),
        this.graphs.numberOfPeople.yData.MEN.reduce((a, b) => a + b, 0),
        this.graphs.numberOfPeople.yData.GIRLS.reduce((a, b) => a + b, 0),
        this.graphs.numberOfPeople.yData.BOYS.reduce((a, b) => a + b, 0),
        this.graphs.numberOfPeople.yData.BABIES.reduce((a, b) => a + b, 0),
      ]
    },
    updateCounter(mean_factor = 1) {
      this.graphs.numberOfPeople.yData = this.randomEntriesForAllClasses(mean_factor)
      this.graphs.visitors.yData = [this.sumVisitors(), this.randomVisitLength()]
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
