<template>
    <line-chart :options="Options" :chartData="Data"></line-chart>
</template>

<script>
  import LineChart from '@/components/BaseCharts/LineChart'
  import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
  import { PastelOne9 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.brewer';

  export default {
    name: "AverageMonthlyIncomeBarChart",
    props: [
      'ChartData',
      'CustomLabels',
    ],
    components: {
      LineChart,
    },
    data() {
      return {
        Data: {},
        Options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: 'bottom',
            rtl: true,
          },
          plugins: {
            datalabels: {
              textAlign: 'center',
              formatter: (value) => {
                var ret = value;
                return ret;
              },
              display: 'auto',
              clamp: true
            },
            colorschemes: {
              scheme: PastelOne9,
            }
          }
        },
      }
    },
    methods: {
      updateData() {
        this.Data = {
          labels: this.CustomLabels,
          datasets: [
            {
              data: this.ChartData.WOMEN,
              label: "WOMEN",
              fill: true,
              borderWidth: 2
            },
            {
              data: this.ChartData.MEN,
              label: "MEN",
              fill: true,
              borderWidth: 2
            },
            {
              data: this.ChartData.GIRLS,
              label: "GIRL",
              fill: true,
              borderWidth: 2
            },
            {
              data: this.ChartData.BOYS,
              label: "BOY",
              fill: true,
              borderWidth: 2
            },
            {
              data: this.ChartData.BABIES,
              label: "BABY",
              fill: true,
              borderWidth: 2
            }
          ],
        }
      }
    },
    mounted() {
      this.updateData()
    },
    watch: {
      ChartData: {
        handler() {
          this.updateData()
        },
        deep: true
      }
    },
  }
</script>